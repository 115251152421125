<template>
    <div class="w100 bs">
        <div class="w100 bgc_fff" style="border-radius: 2px;">
            <div class="bs w100 pos-tabs-box flex align-center justify-between">
                <el-tabs v-model="activeName" class="pos-tabs bs shrink_0" @tab-click="handleClick">
                    <el-tab-pane label="招聘中" name="first"></el-tab-pane>
                    <el-tab-pane label="已下线" name="second"></el-tab-pane>
                </el-tabs>
                <div>
                    <el-button type="primary" @click="goRP">发布职位</el-button>
                </div>
            </div>
            <div class="pos-tbox flex">
                <div class="pos-tbox-th w100 flex align-center">
                    <el-input v-model="jobTitle" placeholder="请输入简历关键词" class="input-with-select"></el-input>
                    <el-button type="primary" @click="kwClcik" style="padding: 0 20px; margin-left: 4px">
                        <div class="el-icon-search text-20"></div>
                    </el-button>
                </div>
                <div class="pos-tbox-th w100 flex align-center">
                    <div class="text-14 c_101010">工作城市：</div>
                    <el-cascader class="flex-sub" v-model="workCity" clearable @change="getList" :options="cityOp" :show-all-levels="false" />
                </div>
                <div class="pos-tbox-th w100 flex align-center">
                    <div class="text-14 c_101010">期望月薪：</div>
                    <el-select class="flex-sub" v-model="mSalary" clearable @change="salaryChange" placeholder="请选择薪资范围" size="large">
                        <el-option v-for="(item,index) in mSalaryOp" :key="index" :label="item.label"
                            :value="index" />
                    </el-select>
                </div>
            </div>
        </div>
        <!-- con -->
        <div class="w100 bgc_fff pos-con-wrap bs" style="border-radius: 2px;">
            <div style="padding: 16px 0;" class="flex">
                <el-button type="primary" @click="getList" :disabled="tableDataSel.length == 0">批量刷新</el-button>
                <el-button type="primary" @click="allUdState" :disabled="tableDataSel.length == 0">批量{{ activeName == 'first' ? '下线' : '上线' }}
                </el-button>
            </div>
            <div class="w100" v-loading="showloading">
                <el-table ref="multipleTableRef" header-cell-class-name="bgc_fa" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" />
                    <el-table-column label="职位名称" width="120">
                        <template #default="scope">
                            <div @click="goJob(scope.row)">{{ scope.row.name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column property='cusCity' label="地点" width="120" />
                    <el-table-column property="ext.viewCount" label="浏览量" width="120" />
                    <el-table-column property="ext.applyCount" label="应聘量" width="120" />
                    <el-table-column property="ext.waitBackCount" label="待反馈" width="120" />
                    <el-table-column property="updateDate" label="更新时间" />
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">{{activeName == 'first' ? '下线' : '上线'}}</el-button>
                            <el-button size="small" type="primary" @click="handleCopy(scope.$index, scope.row)">复制并新建
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="mt_20 w100 bs flex justify-end">
                    <el-pagination lazy layout="total ,prev,pager,next,sizes,jumper" :total="tableData.length" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="currentPage" :page-sizes="psArr" style="font-size: 20px">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import router from "@/router/index";
import { listHunterJob, listCity, salaryOption, updateHunterJobState } from '@/util/apis'
import { ElLoading, ElMessage } from 'element-plus'
export default defineComponent({
    name: "",
    data() {
        return {
            activeName: 'first',
            jobTitle: '',
            workCity: '',
            mSalary: '',
            cityOp: [],
            mSalaryOp: [] as any,
            tableData: [] as any,
            tableDataSel: [] as any,
            currentPage: 1,
            pagesize: 10,
            psArr: [10, 20, 30],
            showloading: false,
            salaryList:[] as any
        }
    },
    mounted() {
        listCity({ type: '2' }).then(res=>{
            this.cityOp = res.data;
        })
        this.mSalaryOp = salaryOption();
        this.getList();
    },
    methods: {
        handleClick(tab: any, event: Event) {
            this.getList();
        },
        handleSelectionChange(val: any) {
            this.tableDataSel = val;
            console.log('this.tableDataSel', this.tableDataSel)
        },
        handleEdit(index: number, row: any) {
            router.push({ path: "/releasePosition/index", name: 'releasePosition', params: { id: row.id, type: 'edit' } });
        },
        handleDelete(index: number, row: any) {
            console.log(index, row)
            this.uDState(row.id);
        },
        handleCopy(index: number, row: any) {
            router.push({ path: "/releasePosition/index", name: 'releasePosition', params: { id: row.id, type: 'copy' } });
        },
        handleSizeChange(size: number) {
            this.pagesize = size;
        },
        handleCurrentChange(currentPage: number) {
            this.currentPage = currentPage;
        },
        goRP() {
            router.push({ path: "/releasePosition/index"});
        },
        goJob(e: any) {
            router.push({ path: "/jobDetails/index" ,name: 'jobDetails',params:{ id: e.id} });
        },
        salaryChange(e:any){
            console.log('salaryChange',e)
            this.salaryList = e&&e>=0 ? [{salaryMin:this.mSalaryOp[e].salaryMin,salaryMax:this.mSalaryOp[e].salaryMax}] : [];
            this.getList();
        },
        getList() {
            this.showloading = true;
            listHunterJob({
                page: 1,
                pageSize: 1000,
                state: this.activeName == 'first' ? '1' : '2',
                keyword: this.jobTitle,
                cityCode: this.workCity&&this.workCity.length>1?this.workCity[this.workCity.length-1]:'',
                salaryList: this.salaryList
            }).then(res => {
                res.data.list.forEach((element: any) => {
                    element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
                });
                this.tableData = res.data.list;
                this.showloading = false;
            })
        },
        kwClcik() {
             this.getList();
        },
        allUdState(){
            this.uDState(this.tableDataSel.map((item:any)=>item.id).join(','));
        },
        uDState(jobIds:any){
            updateHunterJobState({
                jobIds:jobIds,
                state: this.activeName == 'first' ? '2' : '1'
            }).then(res=>{
                this.getList();
            })
        }
    },
    setup: () => {

    }
})
</script>
<style scoped>
@import "./index.css";
</style>