
import { defineComponent } from 'vue';
import router from "@/router/index";
import { listHunterJob, listCity, salaryOption, updateHunterJobState } from '@/util/apis'
import { ElLoading, ElMessage } from 'element-plus'
export default defineComponent({
    name: "",
    data() {
        return {
            activeName: 'first',
            jobTitle: '',
            workCity: '',
            mSalary: '',
            cityOp: [],
            mSalaryOp: [] as any,
            tableData: [] as any,
            tableDataSel: [] as any,
            currentPage: 1,
            pagesize: 10,
            psArr: [10, 20, 30],
            showloading: false,
            salaryList:[] as any
        }
    },
    mounted() {
        listCity({ type: '2' }).then(res=>{
            this.cityOp = res.data;
        })
        this.mSalaryOp = salaryOption();
        this.getList();
    },
    methods: {
        handleClick(tab: any, event: Event) {
            this.getList();
        },
        handleSelectionChange(val: any) {
            this.tableDataSel = val;
            console.log('this.tableDataSel', this.tableDataSel)
        },
        handleEdit(index: number, row: any) {
            router.push({ path: "/releasePosition/index", name: 'releasePosition', params: { id: row.id, type: 'edit' } });
        },
        handleDelete(index: number, row: any) {
            console.log(index, row)
            this.uDState(row.id);
        },
        handleCopy(index: number, row: any) {
            router.push({ path: "/releasePosition/index", name: 'releasePosition', params: { id: row.id, type: 'copy' } });
        },
        handleSizeChange(size: number) {
            this.pagesize = size;
        },
        handleCurrentChange(currentPage: number) {
            this.currentPage = currentPage;
        },
        goRP() {
            router.push({ path: "/releasePosition/index"});
        },
        goJob(e: any) {
            router.push({ path: "/jobDetails/index" ,name: 'jobDetails',params:{ id: e.id} });
        },
        salaryChange(e:any){
            console.log('salaryChange',e)
            this.salaryList = e&&e>=0 ? [{salaryMin:this.mSalaryOp[e].salaryMin,salaryMax:this.mSalaryOp[e].salaryMax}] : [];
            this.getList();
        },
        getList() {
            this.showloading = true;
            listHunterJob({
                page: 1,
                pageSize: 1000,
                state: this.activeName == 'first' ? '1' : '2',
                keyword: this.jobTitle,
                cityCode: this.workCity&&this.workCity.length>1?this.workCity[this.workCity.length-1]:'',
                salaryList: this.salaryList
            }).then(res => {
                res.data.list.forEach((element: any) => {
                    element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
                });
                this.tableData = res.data.list;
                this.showloading = false;
            })
        },
        kwClcik() {
             this.getList();
        },
        allUdState(){
            this.uDState(this.tableDataSel.map((item:any)=>item.id).join(','));
        },
        uDState(jobIds:any){
            updateHunterJobState({
                jobIds:jobIds,
                state: this.activeName == 'first' ? '2' : '1'
            }).then(res=>{
                this.getList();
            })
        }
    },
    setup: () => {

    }
})
